<template>
<el-dialog v-model="visible" title="订单详情" :before-close="close">
  <div class="dialog-body detail-for-warehouse-receipt" v-loading="loading">
    <div class="dfwr-title">基本信息</div>
    <table class="dfwr-base-info-table my-table">
      <tr>
        <th>审批号</th>
        <td colspan="3">{{dialogData.txt_comments}}</td>
      </tr>
      <tr>
        <th>订单类型</th>
        <td>
          <template v-if="dialogData.txt_order_type=='C'">耗材订购</template>
          <template v-else>设备申领</template>
        </td>
        <th>申领时间</th>
        <td>{{dialogData.dt_create}}</td>
      </tr>
      <tr>
        <th>订单总额</th>
        <td>{{dialogData.dec_order_amount}}</td>
        <th>订单状态</th>
        <td>
          <template v-if="dialogData.txt_order_status == -1">已取消</template>
          <template v-else-if="dialogData.txt_order_status == 0">未支付</template>
          <template v-else-if="dialogData.txt_order_status == 1">待出库</template>
          <template v-else-if="dialogData.txt_order_status == 2">出库中</template>
          <template v-else-if="dialogData.txt_order_status == 3">已发货</template>
        </td>
      </tr>
      <tr>
        <th>联系人</th>
        <td>{{dialogData.txt_contact_name}}</td>
        <th>联系方式</th>
        <td>{{dialogData.txt_contact_phone}}</td>
      </tr>
      <tr>
        <th>配送地址</th>
        <td colspan="3">{{dialogData.txt_contact_address}}</td>
      </tr>
    </table>
    <div class="dfwr-title">申领详情</div>
    <table class="my-table">
      <tr>
        <th>商品名称</th>
        <th>申领数量</th>
        <th>申领单价</th>
        <th>申领总价</th>
      </tr>
      <tr v-for="(item, index) in dialogData.orderline" :key="index">
        <td>{{item.txt_commodity_name}}</td>
        <td>{{item.int_purchase_num}}</td>
        <td>
          <template v-if="dialogData.txt_order_type=='C'">￥{{item.dec_consumables_price}}</template>
          <template v-else>￥{{item.dec_device_price}}</template>
        </td>
        <td>￥{{item.dec_order_amount}}</td>
      </tr>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 合伙人详情
import { wareHouseOrderDetail } from "api/apis.js";
import { onMounted, reactive, toRefs } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  props: { detailData: { type: Object } },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      loading: false,
      dialogData: []
    });

    const close = () => {
      ctx.emit("close");
    };

    onMounted(() => {
      getDetail();
    })

    // 获取详情
    const getDetail = () => {
      state.loading = true;
      wareHouseOrderDetail({
        t_order_id: prop.detailData.t_level3_order_id
      }).then(response => {
        if (response.code == 200) {
          state.dialogData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    return {
      ...toRefs(state),
      close,
      getDetail
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-for-warehouse-receipt {
  .dfwr-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
