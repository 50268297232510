<template>
<div class="warehouse-receipts-index" id="page">
  <el-row class="wri-form-line row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item prop="keyword">
          <el-input v-model="form.keyword" placeholder="姓名/联系方式/审批号">
            <template #prepend>
              <i class="iconfont icon-sousuo" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="dateRange">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="订单类型" prop="orderType">
          <el-select v-model="form.orderType" placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.orderTypeOption" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态" prop="orderStatus">
          <el-select v-model="form.orderStatus" placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.orderStatusOption" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">搜索</el-button>
          <el-button type="info" @click="reset()">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row>
      <el-button type="primary" @click="dialog.consumableShow=true">耗材订购</el-button>
      <el-button type="primary" @click="dialog.equipmentShow=true">设备申领</el-button>
    </el-row>
  </el-row>
  <el-table :data="table.tableData" v-loading="table.tabelLoading">
    <el-table-column prop="txt_contact_name" label="联系人" align="center">
      <template #default="scope">{{scope.row.txt_contact_name||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_contact_phone" label="联系方式" align="center">
      <template #default="scope">{{scope.row.txt_contact_phone||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_contact_address" label="配送地址" :show-overflow-tooltip="true" align="center">
      <template #default="scope">{{scope.row.txt_contact_address||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_order_type" label="订单类型" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_order_type=='C'">耗材订购</template>
        <template v-else>设备申领</template>
      </template>
    </el-table-column>
    <el-table-column prop="dec_order_amount" label="订单总额（元）" :show-overflow-tooltip="true" align="center">
      <template #default="scope">{{scope.row.dec_order_amount||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_order_status" label="订单状态" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_order_status == -1">已取消</template>
        <template v-else-if="scope.row.txt_order_status == 0">未支付</template>
        <template v-else-if="scope.row.txt_order_status == 1">待出库</template>
        <template v-else-if="scope.row.txt_order_status == 2">出库中</template>
        <template v-else-if="scope.row.txt_order_status == 3">已发货</template>
      </template>
    </el-table-column>
    <el-table-column prop="txt_express_number" label="快递单号" align="center">
      <template #default="scope">{{scope.row.txt_express_number||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="dt_create" label="创建时间" align="center">
      <template #default="scope">{{scope.row.dt_create||"- -"}}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" @click="handleDetail(scope.row)">
          <el-icon>
            <Document />
          </el-icon> 详情
        </div>
        <!-- 只有出库中 可取消 -->
        <div class="table-icon" @click="handleCancel(scope.row)" v-if="scope.row.txt_order_status == 2">
          <el-icon>
            <Close />
          </el-icon> 取消订单
        </div>
        <!-- 待出库状态下展示 手动出库 -->
        <div class="table-icon" @click="handleWarehouse(scope.row)" v-if="scope.row.txt_order_status == 1">
          <el-icon>
            <Position />
          </el-icon> 出库
        </div>
      </template>
    </el-table-column>
  </el-table>
  <pagination :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <detail v-if="dialog.detailShow" :detailData="dialog.detailData" @close="()=>{dialog.detailShow=false;dialog.detailData={};}" />
  <consumable v-if="dialog.consumableShow" @close="(e) => {dialog.consumableShow=false; if(e){getList()};}" />
  <equipment-for v-if="dialog.equipmentShow" @close="(e) => {dialog.equipmentShow=false; if(e){getList()};}" />
</div>
</template>

<script>
// 库存管理 - 创建出库单
import { useStore } from 'vuex';
import { Pagination } from "components";
import Detail from "./comps/detail.vue";
import Consumable from "./comps/consumable.vue";
import { onMounted, reactive, toRefs } from 'vue';
import EquipmentFor from './comps/equipmentFor.vue';
import { ElMessage, ElMessageBox } from "element-plus";
import { Document, Close, Position } from "@element-plus/icons-vue";
import { getWareHouseOrderList, cancelOrder, deliveryOrder } from "api/apis.js";
export default {
  components: {
    Pagination,
    Document,
    Close,
    Detail,
    Consumable,
    EquipmentFor,
    Position
  },
  setup() {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      form: {
        keyword: "",
        dateRange: [],
        orderStatus: "",
        orderType: "",
        limit: 10,
        page: 1
      },
      options: {
        orderTypeOption: [
          { id: 0, label: "全部", value: "" },
          { id: 1, label: "设备申领", value: "D" },
          { id: 2, label: "耗材订购", value: "C" }
        ],
        orderStatusOption: [
          { id: 0, label: "全部", value: "" },
          { id: 1, label: "未支付", value: "0" },
          { id: 1, label: "出库中", value: 2 },
          { id: 2, label: "已取消", value: -1 },
          { id: 3, label: "已发货", value: 3 },
          { id: 3, label: "待出库", value: 1 },
        ]
      },
      table: {
        tableData: [],
        tabelLoading: false,
        total: 0,
      },
      dialog: {
        detailShow: false,
        consumableShow: false,
        equipmentShow: false,
        detailData: {}
      }
    });

    onMounted(() => {
      getList();
    });

    // 获取列表数据
    const getList = () => {
      state.table.tabelLoading = true;
      let param = {
        searchKey: state.form.keyword,
        dt_from: (state.form.dateRange || []).length > 0 ? state.form.dateRange[0] : "",
        dt_to: (state.form.dateRange || []).length > 0 ? state.form.dateRange[1] : "",
        txt_order_type: state.form.orderType,
        txt_order_status: state.form.orderStatus,
        page: state.form.page,
        limit: state.form.limit
      }
      for (let i = 0; i < Object.keys(param).length; i++) {
        let key = Object.keys(param)[i];
        if (param[key] == "" && key != "txt_order_status") {
          i -= 1;
          delete param[key];
        }
      }
      if (param.txt_order_status == "0") param.txt_order_status = parseInt(param.txt_order_status);
      else if (param.txt_order_status == "") delete param.txt_order_status;
      getWareHouseOrderList(param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.table.tableData = response.data.orderList;
            state.table.total = response.data.count;
          } else {
            state.table.tableData = [];
            state.table.total = 0;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tabelLoading = false;
      })
    };

    // 重置表单
    const reset = () => {
      state.form = {
        keyword: "",
        dateRange: [],
        orderStatus: "",
        orderType: "",
        limit: state.form.limit,
        page: state.form.page,
      }
      getList();
    };

    // 出库
    const handleWarehouse = (item) => {
      state.table.tabelLoading = true;
      deliveryOrder({
        order_id: item.t_level3_order_id,
        txt_updater: store.getters.userInfo.name || "系统用户",
        dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success("出库成功");
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tabelLoading = false;
      })
    };

    // 查看详情
    const handleDetail = (item) => {
      state.dialog.detailData = item;
      state.dialog.detailShow = true;
    };

    // 取消订单
    const handleCancel = (item) => {
      ElMessageBox.confirm(
        `确定要取消${item.txt_contact_name}的订单吗？`,
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        state.table.tabelLoading = true;
        cancelOrder({
          t_order_id: item.t_level3_order_id,
          orderType: "XSCK",
          txt_updater: store.getters.userInfo.name || "系统用户",
          dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("取消成功");
            getList();
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.tabelLoading = false;
        })
      })
    };

    return {
      ...toRefs(state),
      getList,
      reset,
      handleDetail,
      handleCancel,
      handleWarehouse
    }
  }
}
</script>

<style lang="scss" scoped>
.warehouse-receipts-index {
  height: 100%;

  .el-table {
    height: calc(100% - 95px);
  }
}
</style>
