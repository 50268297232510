<template>
<el-dialog v-model="visible" title="耗材订购" :close-on-click-modal="false" :before-close="close">
  <div class="consumable-dialog-for-warehouse-receipt">
    <div class="cdfwr-title">基本信息</div>
    <el-form :model="form" class="cdfwr-base-info" :rules="rules" ref="ruleFormRef">
      <el-row>
        <el-col :span="8">
          <el-form-item label="审批号：" prop="approval_num">
            <el-input placeholder="请输入钉钉审批号" v-model="form.approval_num" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系人：" prop="txt_contact_name">
            <el-input placeholder="请输入收件人姓名" v-model="form.txt_contact_name" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式：" prop="txt_contact_phone">
            <el-input placeholder="请输入收件人联系方式" v-model="form.txt_contact_phone" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="配送地址：" prop="txt_contact_address">
            <el-input placeholder="请输入收件地址" v-model="form.txt_contact_address" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="cdfwr-title">订购详情</div>
    <table class="my-table" v-loading="loading">
      <tr>
        <th>耗材名称</th>
        <th>库存数量（份）</th>
        <th>商品单价（元）</th>
        <th>订购数量</th>
      </tr>
      <tr v-for="(item, index) in consumableData" :key="index">
        <td>{{item.txt_commodity_name}}</td>
        <td>{{item.sum_current_storage||0}}</td>
        <td>￥{{item.dec_commodity_price||0.00}}</td>
        <td :class="parseInt(item.sum_current_storage||0)<=0?'tdDisabled':''">
          <el-input :disabled="parseInt(item.sum_current_storage||0)<=0" v-model="item.want" :placeholder="parseInt(item.sum_current_storage||0)<=0?'库存不足，无法订购':'请输入订购数量'" @input="handleInput($event, item)" />
        </td>
      </tr>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close(true)" type="primary">确认订购</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 创建出库单 - 耗材订购
import { useStore } from 'vuex';
import { onMounted, reactive, toRefs, ref } from 'vue';
import { getGoodsListByType, createWareHouseOrder } from "api/apis.js";
import { ElMessage, ElMessageBox, FormInstance } from 'element-plus';
export default {
  setup(prop, ctx) {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      ruleFormRef: ref(FormInstance),
      visible: true,
      form: {
        approval_num: "",
        txt_contact_name: "",
        txt_contact_phone: "",
        txt_contact_address: ""
      },
      loading: false,
      consumableData: [],
      rules: {
        txt_contact_name: [{ required: true, message: "请输入收件人姓名", trigger: "blur" }],
        txt_contact_phone: [
          { required: true, message: "请输入收件人联系方式", trigger: "blur" },
          // { required: true, message: "手机号格式不正确", pattern: /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/, trigger: "blur" },
          { required: true, message: "手机号格式不正确", pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, trigger: "blur" },
        ],
        txt_contact_address: [{ required: true, message: "请输入收件地址", trigger: "blur" }]
      },
    });

    onMounted(() => {
      getGoods();
    });

    // 获取耗材列表
    const getGoods = () => {
      state.loading = true;
      getGoodsListByType({ txt_goods_type: "C" }).then(response => {
        if (response.code == 200) {
          state.consumableData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // dialog 关闭事件
    const close = (type = false) => {
      if (type == true) {
        // 启动必填校验
        state.ruleFormRef.validate(valid => {
          if (valid) {
            // 判断 耗材订购数量 为空不能提交
            let tempFilter = state.consumableData.filter(t => parseInt(t.sum_current_storage) > 0);
            if (tempFilter.length > 0) {
              let total = tempFilter.reduce((a, b) => a + parseInt(b.want || 0), 0);
              if (total <= 0) {
                ElMessageBox.confirm(
                  "请至少订购一种耗材",
                  "提示", {
                    distinguishCancelAndClose: true,
                    showCancelButton: false,
                    confirmButtonText: '确定'
                  }
                )
              } else {
                let amount = tempFilter.reduce((a, b) => a + (b.want || 0) * parseFloat(b.dec_commodity_price), 0);
                ElMessageBox.alert(
                  '商品订购总量为<span class="cdfwr-message-box-num"> ' + total + ' </span>份，总金额为<span class="cdfwr-message-box-num"> ' + amount.toFixed(2) + ' </span>元，确认是否订购？',
                  '提示', {
                    distinguishCancelAndClose: true,
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                  }
                ).then(() => {
                  state.loading = true;
                  let params = {
                    approval_num: state.form.approval_num,
                    txt_order_type: "C",
                    txt_contact_name: state.form.txt_contact_name,
                    txt_contact_phone: state.form.txt_contact_phone,
                    txt_contact_address: state.form.txt_contact_address,
                    txt_creator: store.getters.userInfo.name || "系统用户",
                    dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                    orderline: tempFilter.filter(m => m.want != undefined).map(t => {
                      return {
                        t_commodity_id: t.t_commodity_id,
                        int_purchase_num: parseInt(t.want),
                        dec_commodity_price: t.dec_commodity_price
                      }
                    })
                  }
                  if (params.approval_num == "") delete params.approval_num;
                  createWareHouseOrder(params).then(response => {
                    if (response.code == 200) {
                      ElMessage.success("订购成功");
                      ctx.emit("close", true);
                    } else {
                      ElMessage.error(response.msg);
                    }
                  }).catch(e => {
                    console.error(e);
                  }).finally(() => {
                    state.loading = false;
                  })
                })
              }
            }
          }
        })
      } else {
        ElMessageBox.confirm(
          `是否取消当前订购？`,
          '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          console.log("取消");
          ctx.emit("close", false);
        })
      }
    }

    // 输入框改变事件
    const handleInput = (event, item) => {
      let target = event.replace(/[^\d]/g, ""); // 清除"数字"以外的字符
      target = target.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
      if (parseInt(target) > parseInt(item.sum_current_storage)) {
        item.want = item.sum_current_storage;
      } else {
        item.want = target;
      }
    };

    return {
      ...toRefs(state),
      close,
      handleInput
    }
  }
}
</script>

<style lang="scss" scoped>
.consumable-dialog-for-warehouse-receipt {
  .cdfwr-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .cdfwr-base-info {
    margin: 8px 0 0 16px;
    width: calc(100% - 16px);
  }

  .tdDisabled {
    cursor: not-allowed;
  }
}
</style><style lang="scss">
.cdfwr-message-box-num {
  color: #409eff;
  font-weight: bold;
}
</style>
